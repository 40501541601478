@import url('https://fonts.cdnfonts.com/css/segoe-ui-variable-static-display');

* {
  font-family: 'Segoe UI Variable Static Text', sans-serif;
  box-sizing: border-box;
}
body {
  background-image: url(./bg.jpg)
}
.topBar {
  color: #3a2c1d;
  height: 120px;
  font-weight: bold;
  font-size: 40px;
  width: 100%;
  display: flex;
  align-content: center;
  flex-direction: row;
}
.logo {
  align-items: center;
  width: 140px;
  filter: drop-shadow(9px 11px 10px rgba(0, 0, 0, 0.5));
  margin-top:5px;
  z-index: 5000;
}
.topLeft {
  display: flex;
  align-items: center;
  width: 40%;
  text-align: left;
  padding-left: 30px;
  background-image: url(./left-bg.svg);
  background-repeat: no-repeat;
  background-position: left bottom;
}
.topCenter {
  width: 20%;
  text-align: center;
}
.topRight {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
  padding-right: 30px;
  background-image: url(./right-bg.svg);
  background-position: right bottom;
  background-repeat: no-repeat;
  font-size:30px;
}

.icon {
  margin-right:20px;
  margin-left:20px;
  width:50px;
}

.card_box {
  display:flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 10px;
  width: 18%;
  border: 2px solid black;
  border-radius: 25px;
  height: 155px;
}

.card_left_wrapper {
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: #BFD9D9;
  padding:25px 5px;
  margin:10px;
  border-radius: 15px;
  height:80%;
}

.place_floor {
  color: #954943;
  text-align: center;
  text-transform: uppercase;
  font-size:18px;
  font-weight: bold;
  margin:0;
}

.place_number {
  font-size: 30px;
  color: #000;
  text-align: center;
  font-weight: bold;
}

.card_right_wrapper {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.doctor_name {
  display:flex;
  align-items: center;
  font-size:18px;
  font-weight:bold;
  background-color: #BFD9D9;
  border-radius: 10px;
  margin:0;
  width:95%;
  padding: 2px 10px;
  margin-bottom:5px;
  min-height: 46px;
}

.card_inner {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-transform: uppercase;
}
.institute {
margin:0;
  padding-left: 10px;
}
.card_space {
  background-color: #3a2c1d;
  color: #fff;
  padding: 13px 20px;
  font-weight: bold;
  font-size: 20px;
}
.card_time {
  font-size: 18px;
  color: #3a2c1d;
  font-weight: bold;
  padding-left: 10px;
  padding-top:5px;
}
.wrapper {
  width:100%;
  padding-left:2.5%;
}
.cardholder {
  width:100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  column-count: 5;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  max-height: 90vh;
  margin-top: 45px;
}

.out_of_office {
  background-color: #f84c4c;
  color: white;
  font-weight: bold;
  padding: 10px 50px;
  font-size: 20px;
}
.specialization-title {
  display: block;
  width: 30%;

}

.specialization-name {
  margin:0;
  padding-left: 10px;
  font-size:14px;
}
